<template>
  <button v-if="isShowButtonUp" aria-label="scroll-to-top" class="scroll-up" @click="scrollUp">
    <Icon name="chevron" stroke="white" width="24px" height="24px" />
  </button>
</template>

<script lang="ts" setup>
// Represents the current scroll position
const scroll = ref(0);

onMounted(() => {
  window.addEventListener('scroll', onScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll);
});

function onScroll(): void {
  scroll.value = window.scrollY;
}
const isShowButtonUp = computed(() => scroll.value > 0);

function scrollUp(): void {
  window.scrollTo(0, 0);
}
</script>

<style lang="scss" scoped>
@use '$/button.scss';
@use '$/colors.scss';

.scroll-up {
  @include button.plain($size: small, $color: colors.$green);

  position: fixed;
  z-index: 100;
  right: 24px;
  bottom: 0;
  bottom: 80px;
  transform: rotate(180deg);

  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;
  margin-left: auto;
  padding: 0;

  border-radius: 100%;
}
</style>
